<template>
    <div>
      <el-dialog :visible.sync="sta.show">
        <span slot="title">新增/修改 人员</span>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label><b>角色</b></label>
              <el-select v-model="model.ROLE" placeholder="无" clearable style="display: block">
                <el-option :label="it.TXT" :value="it.ROLE" v-for="it in roles" :key="it.ROLE" />
              </el-select>
            </div>
  <!--          <div v-if="model.ROLE=='OUT'">-->
  <!--            <div class="form-group">-->
  <!--              <label><b>姓名</b></label>-->
  <!--              <el-input type="text" v-model="model.NAME" />-->
  <!--            </div>-->
  <!--            <div class="form-group">-->
  <!--              <label><b>手机</b></label>-->
  <!--              <el-input type="text" v-model="model.MOBILE"/>-->
  <!--            </div>-->
  <!--          </div>-->
            <div>
              <div class="form-group">
                <label><b>选择教师</b></label>
                <el-select v-model="model.TEA_ID" filterable placeholder="请选择教师" clearable style="display: block">
                  <el-option :label="it.NAME" :value="it.ID" v-for="it in teachers" :key="it.ID" />
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" v-loading="sta.loading">
          <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
          <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data(){
      return{
        sta:{
          show:false,
          loading:false
        },
        model: {ROLE:'SafetyInspector'},
        roles:[{ROLE:'SafetyInspector',TXT:'安全巡检人'},{ROLE:'SafetyHeader',TXT:'安全负责人'}],
        teachers:[]
      }
    },
    methods:{
      init(model){
        this.sta={show:true,loading:false}
        if (model==undefined || model==null) this.model={ROLE:'SafetyInspector'};
        else {
          this.model=JSON.parse((JSON.stringify(model)));
        }
        if (this.teachers.length==0) this.getTeachers();
      },
      getTeachers(){
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/School/ORG/TeacherApi/GetList",
          data: {PAGE_SIZE: 0},
          completed: function (its) {
            self.teachers=its;
          }
        })
      },
      submit(){
        let tea=this.teachers.find(p=>p.ID==this.model.TEA_ID);
        if (tea ==null || tea ==undefined) {
          this.whale.toast.err("请选择一个教师")
          return;
        }
        this.model.NAME=tea.NAME;
        this.model.MOBILE=tea.MOBILE;
        let self=this;
        self.sta.loading=true;
        this.whale.remote.getResult({
          url:"/api/School/SAFE/SafeMenderApi/Save",
          data:self.model,
          finally(){self.sta.loading=false;},
          completed:function (){
            self.sta.show=false;
            self.$emit("on-saved");
          }
        })
      }
    }
  }
  </script>
  